import EmptyCart from './empty-cart/empty-cart.vue';
import MarketOffer from './shared/market-offer.vue';
export default {
  name: 'PurchaseCart',
  components: {
    MarketOffer,
    EmptyCart
  },
  methods: {
    getI18nMessage: function (key) {
      return window.Granite.I18n.get(key);
    }
  }
};