import OrderSummary from './../shared/order-summary.vue';
import QuickAdd from './../shared/quick-add.vue';
import Search from '../../blocks/input-elements/search-box';
export default {
  name: 'EmptyCart',
  components: {
    OrderSummary,
    QuickAdd,
    Search
  },
  data() {
    return {
      previousPurchasesURL: 'my-account?#/previousPurchases',
      partNumbersURL: 'my-account?#/myPartNumbers',
      favoritesURL: 'my-account?#/favorites'
    };
  },
  methods: {
    getI18nMessage: function (key) {
      return window.Granite.I18n.get(key);
    }
  }
};