import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "purchase-cart-heading"
};
const _hoisted_2 = {
  class: "purchase-cart-heading-title"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_MarketOffer = _resolveComponent("MarketOffer");
  const _component_EmptyCart = _resolveComponent("EmptyCart");
  return _openBlock(), _createBlock(_Teleport, {
    to: "#purchaseCart"
  }, [_createElementVNode("div", _hoisted_1, [_createElementVNode("h2", _hoisted_2, _toDisplayString($options.getI18nMessage('cart.page.name')), 1)]), _createVNode(_component_MarketOffer), _createVNode(_component_EmptyCart)]);
}