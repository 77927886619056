import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "empty-cart"
};
const _hoisted_2 = {
  class: "empty-cart-body"
};
const _hoisted_3 = {
  class: "empty-cart-body-title"
};
const _hoisted_4 = {
  class: "empty-cart-body-search"
};
const _hoisted_5 = {
  class: "empty-cart-body-options"
};
const _hoisted_6 = {
  class: "empty-cart-body-options-title"
};
const _hoisted_7 = {
  class: "empty-cart-body-options-span"
};
const _hoisted_8 = ["href"];
const _hoisted_9 = {
  class: "empty-cart-body-options-span"
};
const _hoisted_10 = ["href"];
const _hoisted_11 = {
  class: "empty-cart-body-options-span"
};
const _hoisted_12 = ["href"];
const _hoisted_13 = {
  class: "empty-cart-right-rail"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Search = _resolveComponent("Search");
  const _component_OrderSummary = _resolveComponent("OrderSummary");
  const _component_QuickAdd = _resolveComponent("QuickAdd");
  return _openBlock(), _createElementBlock("div", null, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("h3", _hoisted_3, _toDisplayString($options.getI18nMessage('cart.emptyCart.title')), 1), _createElementVNode("div", _hoisted_4, [_createVNode(_component_Search, {
    activeTab: "#t=Products",
    altPlaceholderText: "productSearchHelpText"
  })]), _createElementVNode("div", _hoisted_5, [_createElementVNode("p", _hoisted_6, _toDisplayString($options.getI18nMessage('cart.emptyCart.msg.part2') + " " + $options.getI18nMessage('myaccount.myItems')), 1), _createElementVNode("span", _hoisted_7, [_createElementVNode("a", {
    class: "empty-cart-body-options-span-link",
    href: $data.previousPurchasesURL
  }, _toDisplayString($options.getI18nMessage('cart.emptyCart.link.part0')), 9, _hoisted_8)]), _createElementVNode("span", _hoisted_9, [_createElementVNode("a", {
    class: "empty-cart-body-options-span-link",
    href: $data.partNumbersURL
  }, _toDisplayString($options.getI18nMessage('cart.emptyCart.link.part1')), 9, _hoisted_10)]), _createElementVNode("span", _hoisted_11, [_createElementVNode("a", {
    class: "empty-cart-body-options-span-link",
    href: $data.favoritesURL
  }, _toDisplayString($options.getI18nMessage('cart.emptyCart.link.part2')), 9, _hoisted_12)])])]), _createElementVNode("div", _hoisted_13, [_createVNode(_component_OrderSummary), _createVNode(_component_QuickAdd)])])]);
}