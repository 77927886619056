import ApiClient from '@/plugins/AxiosPlugin';
import AddToCartHelper from '../../myAccount/helper/add-to-cart-helper';
export default {
  name: 'QuickAdd',
  data() {
    return {
      addMultipleItemsLink: 'quick-order.html?suppressDeliveryAddressChange=true',
      inputText: '',
      isButtonDisabled: true,
      errorMessage: false
    };
  },
  methods: {
    getI18nMessage(key) {
      return window.Granite.I18n.get(key);
    },
    enableButton() {
      this.isButtonDisabled = this.inputText.trim() === '';
    },
    submitInput: async function () {
      let inputContent = document.getElementById('quickAddInput').value;
      if (await this.isValidMaterial(inputContent)) {
        AddToCartHelper.addMaterialToCart(inputContent);
      } else {
        this.errorMessage = true;
      }
    },
    isValidMaterial: async function (materialNum) {
      if (materialNum) {
        let isValidMaterial = false;
        try {
          this.$store.commit('loading/setLoading', true);
          const response = await ApiClient.get(this.$store.state.settings.quick_order + '?materialNumber=' + encodeURIComponent(materialNum));
          if (undefined === response.data?.errorMsg) {
            isValidMaterial = true;
            this.$store.commit('loading/setLoading', false);
          }
        } catch (error) {
          isValidMaterial = false;
          this.$store.commit('loading/setLoading', false);
        }
        return isValidMaterial;
      }
    }
  }
};