import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "order-summary"
};
const _hoisted_2 = {
  class: "order-summary-title"
};
const _hoisted_3 = {
  class: "order-summary-subtotal"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("h4", _hoisted_2, _toDisplayString($options.getLabel('order.summary.Title')), 1), _createElementVNode("p", _hoisted_3, _toDisplayString($options.getLabel('subtotal')), 1)]);
}