import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, withKeys as _withKeys, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
  class: "quick-add"
};
const _hoisted_2 = {
  class: "quick-add-title"
};
const _hoisted_3 = {
  class: "quick-add-form"
};
const _hoisted_4 = ["aria-label", "placeholder"];
const _hoisted_5 = ["aria-label", "disabled", "value"];
const _hoisted_6 = ["innerHTML"];
const _hoisted_7 = ["href"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("h4", _hoisted_2, _toDisplayString($options.getI18nMessage('cart.rightRail.quickAdd')), 1), _createElementVNode("div", _hoisted_3, [_withDirectives(_createElementVNode("input", {
    class: _normalizeClass(["quick-add-form-number", {
      'quick-add-form-error-outline': $data.errorMessage
    }]),
    "aria-label": $options.getI18nMessage('quickOrderForm.body.td.input.placeHolder'),
    id: "quickAddInput",
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $data.inputText = $event),
    onInput: _cache[1] || (_cache[1] = (...args) => $options.enableButton && $options.enableButton(...args)),
    type: "text",
    placeholder: $options.getI18nMessage('quickOrderForm.body.td.input.placeHolder')
  }, null, 42, _hoisted_4), [[_vModelText, $data.inputText]]), _createElementVNode("input", {
    class: "quick-add-form-button",
    "aria-label": $options.getI18nMessage('button.add.UPPER'),
    type: "button",
    disabled: $data.isButtonDisabled,
    value: $options.getI18nMessage('button.add.UPPER'),
    onClick: _cache[2] || (_cache[2] = (...args) => $options.submitInput && $options.submitInput(...args)),
    onKeydown: _cache[3] || (_cache[3] = _withKeys((...args) => $options.submitInput && $options.submitInput(...args), ["enter"]))
  }, null, 40, _hoisted_5), $data.errorMessage ? (_openBlock(), _createElementBlock("p", {
    key: 0,
    class: "quick-add-form-error",
    innerHTML: $options.getI18nMessage('quickOrderForm.validate.material')
  }, null, 8, _hoisted_6)) : _createCommentVNode("", true)]), _createElementVNode("a", {
    class: "quick-add-text",
    href: $data.addMultipleItemsLink
  }, "+ " + _toDisplayString($options.getI18nMessage('cart.rightRail.addMultipleItems')), 9, _hoisted_7)]);
}